<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <h1>Login</h1>

        <div v-if="loggingIn">
          <p>Inloggen...</p>
        </div>

        <div v-if="!loggingIn">
          <page-snippet name="login" />
          <p class="mt-4">Vul hieronder de gebruikersnaam en het wachtwoord van uw account in om in te loggen in het besloten gedeelte:</p>

          <div class="alert alert-danger" role="alert" v-if="errorMessage != ''">
            {{ errorMessage }}
          </div>

          <form>
            <table>
              <tr>
                <td><label for="username">Username</label></td>
                <td><b-form-input id="username" type="text" v-model="username" required autofocus /></td>
              </tr>
              <tr>
                <td><label for="password">Password</label></td>
                <td><b-form-input id="password" type="password" v-model="password" required autofocus /></td>
              </tr>
              <tr>
                <td></td>
                <td><b-button variant="primary" type="submit" @click.prevent="handleSubmit">Inloggen</b-button></td>
              </tr>
            </table>
          </form>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import Cookies from 'js-cookie'
  import * as Sentry from '@sentry/browser'

  export default {
    name: "Login",
    components: {
      'page-snippet': () => import('@/components/PageSnippet')
    },
    data () {
      return {
        username: '',
        password: '',
        errorMessage: '',
        loggingIn: false,
      }
    },
    methods: {
      setSentryUser () {
        Sentry.setUser({
          username: this.$root.$data.me.Username,
          email: this.$root.$data.me.Email,
        })
      },
      handleSubmit () {
        if (this.password.length > 0) {
          this.loggingIn = true

          this.$http.post('login', {
            username: this.username,
            password: this.password
          })
          .then(response => {
            localStorage.setItem('user', JSON.stringify())
            localStorage.setItem('jwt', response.data.token)
            Cookies.set('jwt', response.data.token)

            this.$root.$data.me = response.data.user
            this.$root.$data.loggedIn = true
            this.setSentryUser()

            this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;

            this.$router.push({ name: 'dashboard'})
          })
          .catch((error) => {
            this.loggingIn = false
            if (error.response.status == 403) {
              this.errorMessage = 'Inloggen is niet gelukt. Controleer uw gebruikersnaam en wachtwoord!'
            } else {
              this.errorMessage = 'Er is een onbekende fout opgetreden. Probeer de pagina opnieuw te laden, en opnieuw in te loggen.'
            }
          });
        }
      }
    },
    mounted () {
      if (this.$root.$data.loggedIn) {
        this.$router.push({ name: 'dashboard'})
        return
      }

      
    }
  };
</script>

<style scoped>
  label {
    font-weight: bold;
  }

  td:first-child {
    padding-right: 40px; 
  }


</style>