var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("h1", [_vm._v("Login")]),
            _vm.loggingIn
              ? _c("div", [_c("p", [_vm._v("Inloggen...")])])
              : _vm._e(),
            !_vm.loggingIn
              ? _c(
                  "div",
                  [
                    _c("page-snippet", { attrs: { name: "login" } }),
                    _c("p", { staticClass: "mt-4" }, [
                      _vm._v(
                        "Vul hieronder de gebruikersnaam en het wachtwoord van uw account in om in te loggen in het besloten gedeelte:"
                      )
                    ]),
                    _vm.errorMessage != ""
                      ? _c(
                          "div",
                          {
                            staticClass: "alert alert-danger",
                            attrs: { role: "alert" }
                          },
                          [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
                        )
                      : _vm._e(),
                    _c("form", [
                      _c("table", [
                        _c("tr", [
                          _c("td", [
                            _c("label", { attrs: { for: "username" } }, [
                              _vm._v("Username")
                            ])
                          ]),
                          _c(
                            "td",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "username",
                                  type: "text",
                                  required: "",
                                  autofocus: ""
                                },
                                model: {
                                  value: _vm.username,
                                  callback: function($$v) {
                                    _vm.username = $$v
                                  },
                                  expression: "username"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("tr", [
                          _c("td", [
                            _c("label", { attrs: { for: "password" } }, [
                              _vm._v("Password")
                            ])
                          ]),
                          _c(
                            "td",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "password",
                                  type: "password",
                                  required: "",
                                  autofocus: ""
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("tr", [
                          _c("td"),
                          _c(
                            "td",
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "primary", type: "submit" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.handleSubmit($event)
                                    }
                                  }
                                },
                                [_vm._v("Inloggen")]
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    ])
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }